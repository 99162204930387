
header.header {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease-out;

  pointer-events: none;
  > * { pointer-events: all; }

  body.ui-hidden & {
    opacity: 0;
    transition: opacity 2s ease-out;
  }

  .logo {
    align-self: start;
    justify-self: left;

    a {
      text-decoration: none;

      &:hover {
        font-style: normal;
      }
    }
  }

  .menu {
    text-align: right;
    align-self: start;
    justify-self: right;

    .menu-item {
      margin: 0;

      a {
        text-decoration: none;
      }
    }
  }

  .toggle-autoscroll {
    text-align: left;
    align-self: end;
    justify-self: left;
    grid-row: 3;
    font-family: $font-mono;
    font-size: 10px;
  }
}
