* {
  box-sizing: border-box;
  font-kerning: none;
}

body {
  margin: 0;
  overflow-x: hidden;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: currentColor;
}

a {
  color: currentColor;
}

body, button, input, textarea {
  font-family: "Suisse Int'l", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
}

.container {
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 12px;
  align-content: start;
  --columns: 12 !important;
}
.container--nopad {
  padding-left: 0;
  padding-right: 0;
}
.container--row-gap {
  row-gap: 12px;
}
@media (min-width: 550px) {
  .container-sm {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-sm--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-sm--row-gap {
    row-gap: 12px;
  }
}
@media (max-width: 549px) {
  .container-sm-down {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-sm-down--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-sm-down--row-gap {
    row-gap: 12px;
  }
}
@media (min-width: 660px) {
  .container-md {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-md--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-md--row-gap {
    row-gap: 12px;
  }
}
@media (max-width: 659px) {
  .container-md-down {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-md-down--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-md-down--row-gap {
    row-gap: 12px;
  }
}
@media (min-width: 770px) {
  .container-lg {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-lg--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-lg--row-gap {
    row-gap: 12px;
  }
}
@media (max-width: 769px) {
  .container-lg-down {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-lg-down--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-lg-down--row-gap {
    row-gap: 12px;
  }
}
@media (min-width: 1024px) {
  .container-xl {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-xl--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-xl--row-gap {
    row-gap: 12px;
  }
}
@media (max-width: 1023px) {
  .container-xl-down {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-xl-down--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-xl-down--row-gap {
    row-gap: 12px;
  }
}
@media (min-width: 1440px) {
  .container-xxl {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-xxl--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-xxl--row-gap {
    row-gap: 12px;
  }
}
@media (max-width: 1439px) {
  .container-xxl-down {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 12px;
    align-content: start;
  }
  .container-xxl-down--nopad {
    padding-left: 0;
    padding-right: 0;
  }
  .container-xxl-down--row-gap {
    row-gap: 12px;
  }
}

[class*=col-].col-span-1 {
  grid-column: span 1;
}
[class*=col-].col-span-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-1 {
  grid-column: 1;
}
[class*=col-].col-1-1 {
  grid-column: 1/span 1;
}
[class*=col-].col-1-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-1-2 {
  grid-column: 1/span 2;
}
[class*=col-].col-1-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-1-3 {
  grid-column: 1/span 3;
}
[class*=col-].col-1-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-1-4 {
  grid-column: 1/span 4;
}
[class*=col-].col-1-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-1-5 {
  grid-column: 1/span 5;
}
[class*=col-].col-1-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-1-6 {
  grid-column: 1/span 6;
}
[class*=col-].col-1-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-1-7 {
  grid-column: 1/span 7;
}
[class*=col-].col-1-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-1-8 {
  grid-column: 1/span 8;
}
[class*=col-].col-1-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-1-9 {
  grid-column: 1/span 9;
}
[class*=col-].col-1-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-1-10 {
  grid-column: 1/span 10;
}
[class*=col-].col-1-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-1-11 {
  grid-column: 1/span 11;
}
[class*=col-].col-1-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-1-12 {
  grid-column: 1/span 12;
}
[class*=col-].col-1-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-2 {
  grid-column: span 2;
}
[class*=col-].col-span-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-2 {
  grid-column: 2;
}
[class*=col-].col-2-1 {
  grid-column: 2/span 1;
}
[class*=col-].col-2-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-2-2 {
  grid-column: 2/span 2;
}
[class*=col-].col-2-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-2-3 {
  grid-column: 2/span 3;
}
[class*=col-].col-2-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-2-4 {
  grid-column: 2/span 4;
}
[class*=col-].col-2-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-2-5 {
  grid-column: 2/span 5;
}
[class*=col-].col-2-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-2-6 {
  grid-column: 2/span 6;
}
[class*=col-].col-2-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-2-7 {
  grid-column: 2/span 7;
}
[class*=col-].col-2-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-2-8 {
  grid-column: 2/span 8;
}
[class*=col-].col-2-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-2-9 {
  grid-column: 2/span 9;
}
[class*=col-].col-2-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-2-10 {
  grid-column: 2/span 10;
}
[class*=col-].col-2-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-2-11 {
  grid-column: 2/span 11;
}
[class*=col-].col-2-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-2-12 {
  grid-column: 2/span 12;
}
[class*=col-].col-2-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-3 {
  grid-column: span 3;
}
[class*=col-].col-span-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-3 {
  grid-column: 3;
}
[class*=col-].col-3-1 {
  grid-column: 3/span 1;
}
[class*=col-].col-3-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-3-2 {
  grid-column: 3/span 2;
}
[class*=col-].col-3-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-3-3 {
  grid-column: 3/span 3;
}
[class*=col-].col-3-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-3-4 {
  grid-column: 3/span 4;
}
[class*=col-].col-3-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-3-5 {
  grid-column: 3/span 5;
}
[class*=col-].col-3-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-3-6 {
  grid-column: 3/span 6;
}
[class*=col-].col-3-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-3-7 {
  grid-column: 3/span 7;
}
[class*=col-].col-3-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-3-8 {
  grid-column: 3/span 8;
}
[class*=col-].col-3-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-3-9 {
  grid-column: 3/span 9;
}
[class*=col-].col-3-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-3-10 {
  grid-column: 3/span 10;
}
[class*=col-].col-3-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-3-11 {
  grid-column: 3/span 11;
}
[class*=col-].col-3-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-3-12 {
  grid-column: 3/span 12;
}
[class*=col-].col-3-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-4 {
  grid-column: span 4;
}
[class*=col-].col-span-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-4 {
  grid-column: 4;
}
[class*=col-].col-4-1 {
  grid-column: 4/span 1;
}
[class*=col-].col-4-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-4-2 {
  grid-column: 4/span 2;
}
[class*=col-].col-4-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-4-3 {
  grid-column: 4/span 3;
}
[class*=col-].col-4-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-4-4 {
  grid-column: 4/span 4;
}
[class*=col-].col-4-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-4-5 {
  grid-column: 4/span 5;
}
[class*=col-].col-4-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-4-6 {
  grid-column: 4/span 6;
}
[class*=col-].col-4-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-4-7 {
  grid-column: 4/span 7;
}
[class*=col-].col-4-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-4-8 {
  grid-column: 4/span 8;
}
[class*=col-].col-4-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-4-9 {
  grid-column: 4/span 9;
}
[class*=col-].col-4-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-4-10 {
  grid-column: 4/span 10;
}
[class*=col-].col-4-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-4-11 {
  grid-column: 4/span 11;
}
[class*=col-].col-4-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-4-12 {
  grid-column: 4/span 12;
}
[class*=col-].col-4-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-5 {
  grid-column: span 5;
}
[class*=col-].col-span-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-5 {
  grid-column: 5;
}
[class*=col-].col-5-1 {
  grid-column: 5/span 1;
}
[class*=col-].col-5-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-5-2 {
  grid-column: 5/span 2;
}
[class*=col-].col-5-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-5-3 {
  grid-column: 5/span 3;
}
[class*=col-].col-5-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-5-4 {
  grid-column: 5/span 4;
}
[class*=col-].col-5-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-5-5 {
  grid-column: 5/span 5;
}
[class*=col-].col-5-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-5-6 {
  grid-column: 5/span 6;
}
[class*=col-].col-5-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-5-7 {
  grid-column: 5/span 7;
}
[class*=col-].col-5-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-5-8 {
  grid-column: 5/span 8;
}
[class*=col-].col-5-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-5-9 {
  grid-column: 5/span 9;
}
[class*=col-].col-5-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-5-10 {
  grid-column: 5/span 10;
}
[class*=col-].col-5-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-5-11 {
  grid-column: 5/span 11;
}
[class*=col-].col-5-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-5-12 {
  grid-column: 5/span 12;
}
[class*=col-].col-5-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-6 {
  grid-column: span 6;
}
[class*=col-].col-span-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-6 {
  grid-column: 6;
}
[class*=col-].col-6-1 {
  grid-column: 6/span 1;
}
[class*=col-].col-6-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-6-2 {
  grid-column: 6/span 2;
}
[class*=col-].col-6-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-6-3 {
  grid-column: 6/span 3;
}
[class*=col-].col-6-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-6-4 {
  grid-column: 6/span 4;
}
[class*=col-].col-6-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-6-5 {
  grid-column: 6/span 5;
}
[class*=col-].col-6-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-6-6 {
  grid-column: 6/span 6;
}
[class*=col-].col-6-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-6-7 {
  grid-column: 6/span 7;
}
[class*=col-].col-6-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-6-8 {
  grid-column: 6/span 8;
}
[class*=col-].col-6-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-6-9 {
  grid-column: 6/span 9;
}
[class*=col-].col-6-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-6-10 {
  grid-column: 6/span 10;
}
[class*=col-].col-6-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-6-11 {
  grid-column: 6/span 11;
}
[class*=col-].col-6-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-6-12 {
  grid-column: 6/span 12;
}
[class*=col-].col-6-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-7 {
  grid-column: span 7;
}
[class*=col-].col-span-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-7 {
  grid-column: 7;
}
[class*=col-].col-7-1 {
  grid-column: 7/span 1;
}
[class*=col-].col-7-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-7-2 {
  grid-column: 7/span 2;
}
[class*=col-].col-7-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-7-3 {
  grid-column: 7/span 3;
}
[class*=col-].col-7-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-7-4 {
  grid-column: 7/span 4;
}
[class*=col-].col-7-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-7-5 {
  grid-column: 7/span 5;
}
[class*=col-].col-7-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-7-6 {
  grid-column: 7/span 6;
}
[class*=col-].col-7-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-7-7 {
  grid-column: 7/span 7;
}
[class*=col-].col-7-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-7-8 {
  grid-column: 7/span 8;
}
[class*=col-].col-7-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-7-9 {
  grid-column: 7/span 9;
}
[class*=col-].col-7-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-7-10 {
  grid-column: 7/span 10;
}
[class*=col-].col-7-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-7-11 {
  grid-column: 7/span 11;
}
[class*=col-].col-7-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-7-12 {
  grid-column: 7/span 12;
}
[class*=col-].col-7-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-8 {
  grid-column: span 8;
}
[class*=col-].col-span-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-8 {
  grid-column: 8;
}
[class*=col-].col-8-1 {
  grid-column: 8/span 1;
}
[class*=col-].col-8-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-8-2 {
  grid-column: 8/span 2;
}
[class*=col-].col-8-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-8-3 {
  grid-column: 8/span 3;
}
[class*=col-].col-8-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-8-4 {
  grid-column: 8/span 4;
}
[class*=col-].col-8-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-8-5 {
  grid-column: 8/span 5;
}
[class*=col-].col-8-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-8-6 {
  grid-column: 8/span 6;
}
[class*=col-].col-8-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-8-7 {
  grid-column: 8/span 7;
}
[class*=col-].col-8-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-8-8 {
  grid-column: 8/span 8;
}
[class*=col-].col-8-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-8-9 {
  grid-column: 8/span 9;
}
[class*=col-].col-8-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-8-10 {
  grid-column: 8/span 10;
}
[class*=col-].col-8-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-8-11 {
  grid-column: 8/span 11;
}
[class*=col-].col-8-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-8-12 {
  grid-column: 8/span 12;
}
[class*=col-].col-8-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-9 {
  grid-column: span 9;
}
[class*=col-].col-span-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-9 {
  grid-column: 9;
}
[class*=col-].col-9-1 {
  grid-column: 9/span 1;
}
[class*=col-].col-9-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-9-2 {
  grid-column: 9/span 2;
}
[class*=col-].col-9-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-9-3 {
  grid-column: 9/span 3;
}
[class*=col-].col-9-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-9-4 {
  grid-column: 9/span 4;
}
[class*=col-].col-9-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-9-5 {
  grid-column: 9/span 5;
}
[class*=col-].col-9-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-9-6 {
  grid-column: 9/span 6;
}
[class*=col-].col-9-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-9-7 {
  grid-column: 9/span 7;
}
[class*=col-].col-9-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-9-8 {
  grid-column: 9/span 8;
}
[class*=col-].col-9-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-9-9 {
  grid-column: 9/span 9;
}
[class*=col-].col-9-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-9-10 {
  grid-column: 9/span 10;
}
[class*=col-].col-9-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-9-11 {
  grid-column: 9/span 11;
}
[class*=col-].col-9-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-9-12 {
  grid-column: 9/span 12;
}
[class*=col-].col-9-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-10 {
  grid-column: span 10;
}
[class*=col-].col-span-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-10 {
  grid-column: 10;
}
[class*=col-].col-10-1 {
  grid-column: 10/span 1;
}
[class*=col-].col-10-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-10-2 {
  grid-column: 10/span 2;
}
[class*=col-].col-10-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-10-3 {
  grid-column: 10/span 3;
}
[class*=col-].col-10-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-10-4 {
  grid-column: 10/span 4;
}
[class*=col-].col-10-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-10-5 {
  grid-column: 10/span 5;
}
[class*=col-].col-10-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-10-6 {
  grid-column: 10/span 6;
}
[class*=col-].col-10-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-10-7 {
  grid-column: 10/span 7;
}
[class*=col-].col-10-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-10-8 {
  grid-column: 10/span 8;
}
[class*=col-].col-10-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-10-9 {
  grid-column: 10/span 9;
}
[class*=col-].col-10-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-10-10 {
  grid-column: 10/span 10;
}
[class*=col-].col-10-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-10-11 {
  grid-column: 10/span 11;
}
[class*=col-].col-10-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-10-12 {
  grid-column: 10/span 12;
}
[class*=col-].col-10-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-11 {
  grid-column: span 11;
}
[class*=col-].col-span-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-11 {
  grid-column: 11;
}
[class*=col-].col-11-1 {
  grid-column: 11/span 1;
}
[class*=col-].col-11-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-11-2 {
  grid-column: 11/span 2;
}
[class*=col-].col-11-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-11-3 {
  grid-column: 11/span 3;
}
[class*=col-].col-11-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-11-4 {
  grid-column: 11/span 4;
}
[class*=col-].col-11-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-11-5 {
  grid-column: 11/span 5;
}
[class*=col-].col-11-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-11-6 {
  grid-column: 11/span 6;
}
[class*=col-].col-11-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-11-7 {
  grid-column: 11/span 7;
}
[class*=col-].col-11-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-11-8 {
  grid-column: 11/span 8;
}
[class*=col-].col-11-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-11-9 {
  grid-column: 11/span 9;
}
[class*=col-].col-11-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-11-10 {
  grid-column: 11/span 10;
}
[class*=col-].col-11-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-11-11 {
  grid-column: 11/span 11;
}
[class*=col-].col-11-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-11-12 {
  grid-column: 11/span 12;
}
[class*=col-].col-11-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-span-12 {
  grid-column: span 12;
}
[class*=col-].col-span-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
[class*=col-].col-12 {
  grid-column: 12;
}
[class*=col-].col-12-1 {
  grid-column: 12/span 1;
}
[class*=col-].col-12-1[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(1, 1fr);
}
[class*=col-].col-12-2 {
  grid-column: 12/span 2;
}
[class*=col-].col-12-2[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(2, 1fr);
}
[class*=col-].col-12-3 {
  grid-column: 12/span 3;
}
[class*=col-].col-12-3[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(3, 1fr);
}
[class*=col-].col-12-4 {
  grid-column: 12/span 4;
}
[class*=col-].col-12-4[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(4, 1fr);
}
[class*=col-].col-12-5 {
  grid-column: 12/span 5;
}
[class*=col-].col-12-5[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(5, 1fr);
}
[class*=col-].col-12-6 {
  grid-column: 12/span 6;
}
[class*=col-].col-12-6[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(6, 1fr);
}
[class*=col-].col-12-7 {
  grid-column: 12/span 7;
}
[class*=col-].col-12-7[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(7, 1fr);
}
[class*=col-].col-12-8 {
  grid-column: 12/span 8;
}
[class*=col-].col-12-8[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(8, 1fr);
}
[class*=col-].col-12-9 {
  grid-column: 12/span 9;
}
[class*=col-].col-12-9[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(9, 1fr);
}
[class*=col-].col-12-10 {
  grid-column: 12/span 10;
}
[class*=col-].col-12-10[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(10, 1fr);
}
[class*=col-].col-12-11 {
  grid-column: 12/span 11;
}
[class*=col-].col-12-11[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(11, 1fr);
}
[class*=col-].col-12-12 {
  grid-column: 12/span 12;
}
[class*=col-].col-12-12[class*=container] {
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(12, 1fr);
}
@media (min-width: 550px) {
  [class*=col-].col-sm-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-sm-span-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-1 {
    grid-column: 1;
  }
  [class*=col-].col-sm-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-sm-1-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-sm-1-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-sm-1-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-sm-1-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-sm-1-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-sm-1-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-sm-1-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-sm-1-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-sm-1-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-sm-1-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-sm-1-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-sm-1-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-sm-span-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-2 {
    grid-column: 2;
  }
  [class*=col-].col-sm-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-sm-2-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-sm-2-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-sm-2-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-sm-2-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-sm-2-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-sm-2-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-sm-2-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-sm-2-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-sm-2-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-sm-2-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-sm-2-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-sm-2-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-sm-span-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-3 {
    grid-column: 3;
  }
  [class*=col-].col-sm-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-sm-3-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-sm-3-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-sm-3-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-sm-3-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-sm-3-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-sm-3-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-sm-3-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-sm-3-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-sm-3-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-sm-3-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-sm-3-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-sm-3-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-sm-span-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-4 {
    grid-column: 4;
  }
  [class*=col-].col-sm-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-sm-4-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-sm-4-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-sm-4-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-sm-4-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-sm-4-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-sm-4-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-sm-4-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-sm-4-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-sm-4-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-sm-4-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-sm-4-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-sm-4-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-sm-span-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-5 {
    grid-column: 5;
  }
  [class*=col-].col-sm-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-sm-5-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-sm-5-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-sm-5-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-sm-5-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-sm-5-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-sm-5-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-sm-5-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-sm-5-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-sm-5-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-sm-5-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-sm-5-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-sm-5-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-sm-span-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-6 {
    grid-column: 6;
  }
  [class*=col-].col-sm-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-sm-6-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-sm-6-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-sm-6-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-sm-6-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-sm-6-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-sm-6-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-sm-6-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-sm-6-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-sm-6-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-sm-6-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-sm-6-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-sm-6-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-sm-span-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-7 {
    grid-column: 7;
  }
  [class*=col-].col-sm-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-sm-7-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-sm-7-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-sm-7-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-sm-7-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-sm-7-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-sm-7-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-sm-7-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-sm-7-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-sm-7-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-sm-7-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-sm-7-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-sm-7-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-sm-span-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-8 {
    grid-column: 8;
  }
  [class*=col-].col-sm-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-sm-8-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-sm-8-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-sm-8-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-sm-8-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-sm-8-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-sm-8-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-sm-8-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-sm-8-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-sm-8-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-sm-8-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-sm-8-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-sm-8-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-sm-span-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-9 {
    grid-column: 9;
  }
  [class*=col-].col-sm-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-sm-9-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-sm-9-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-sm-9-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-sm-9-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-sm-9-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-sm-9-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-sm-9-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-sm-9-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-sm-9-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-sm-9-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-sm-9-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-sm-9-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-sm-span-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-10 {
    grid-column: 10;
  }
  [class*=col-].col-sm-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-sm-10-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-sm-10-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-sm-10-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-sm-10-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-sm-10-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-sm-10-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-sm-10-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-sm-10-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-sm-10-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-sm-10-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-sm-10-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-sm-10-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-sm-span-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-11 {
    grid-column: 11;
  }
  [class*=col-].col-sm-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-sm-11-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-sm-11-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-sm-11-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-sm-11-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-sm-11-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-sm-11-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-sm-11-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-sm-11-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-sm-11-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-sm-11-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-sm-11-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-sm-11-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-sm-span-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-sm-12 {
    grid-column: 12;
  }
  [class*=col-].col-sm-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-sm-12-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-sm-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-sm-12-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-sm-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-sm-12-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-sm-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-sm-12-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-sm-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-sm-12-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-sm-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-sm-12-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-sm-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-sm-12-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-sm-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-sm-12-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-sm-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-sm-12-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-sm-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-sm-12-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-sm-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-sm-12-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-sm-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-sm-12-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 549px) {
  [class*=col-].col-sm-down-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-sm-down-span-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-1 {
    grid-column: 1;
  }
  [class*=col-].col-sm-down-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-sm-down-1-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-sm-down-1-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-sm-down-1-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-sm-down-1-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-sm-down-1-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-sm-down-1-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-sm-down-1-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-sm-down-1-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-sm-down-1-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-sm-down-1-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-sm-down-1-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-sm-down-1-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-sm-down-span-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-2 {
    grid-column: 2;
  }
  [class*=col-].col-sm-down-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-sm-down-2-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-sm-down-2-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-sm-down-2-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-sm-down-2-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-sm-down-2-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-sm-down-2-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-sm-down-2-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-sm-down-2-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-sm-down-2-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-sm-down-2-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-sm-down-2-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-sm-down-2-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-sm-down-span-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-3 {
    grid-column: 3;
  }
  [class*=col-].col-sm-down-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-sm-down-3-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-sm-down-3-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-sm-down-3-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-sm-down-3-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-sm-down-3-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-sm-down-3-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-sm-down-3-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-sm-down-3-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-sm-down-3-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-sm-down-3-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-sm-down-3-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-sm-down-3-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-sm-down-span-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-4 {
    grid-column: 4;
  }
  [class*=col-].col-sm-down-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-sm-down-4-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-sm-down-4-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-sm-down-4-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-sm-down-4-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-sm-down-4-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-sm-down-4-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-sm-down-4-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-sm-down-4-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-sm-down-4-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-sm-down-4-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-sm-down-4-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-sm-down-4-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-sm-down-span-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-5 {
    grid-column: 5;
  }
  [class*=col-].col-sm-down-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-sm-down-5-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-sm-down-5-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-sm-down-5-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-sm-down-5-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-sm-down-5-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-sm-down-5-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-sm-down-5-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-sm-down-5-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-sm-down-5-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-sm-down-5-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-sm-down-5-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-sm-down-5-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-sm-down-span-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-6 {
    grid-column: 6;
  }
  [class*=col-].col-sm-down-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-sm-down-6-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-sm-down-6-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-sm-down-6-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-sm-down-6-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-sm-down-6-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-sm-down-6-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-sm-down-6-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-sm-down-6-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-sm-down-6-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-sm-down-6-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-sm-down-6-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-sm-down-6-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-sm-down-span-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-7 {
    grid-column: 7;
  }
  [class*=col-].col-sm-down-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-sm-down-7-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-sm-down-7-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-sm-down-7-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-sm-down-7-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-sm-down-7-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-sm-down-7-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-sm-down-7-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-sm-down-7-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-sm-down-7-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-sm-down-7-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-sm-down-7-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-sm-down-7-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-sm-down-span-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-8 {
    grid-column: 8;
  }
  [class*=col-].col-sm-down-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-sm-down-8-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-sm-down-8-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-sm-down-8-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-sm-down-8-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-sm-down-8-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-sm-down-8-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-sm-down-8-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-sm-down-8-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-sm-down-8-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-sm-down-8-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-sm-down-8-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-sm-down-8-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-sm-down-span-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-9 {
    grid-column: 9;
  }
  [class*=col-].col-sm-down-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-sm-down-9-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-sm-down-9-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-sm-down-9-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-sm-down-9-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-sm-down-9-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-sm-down-9-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-sm-down-9-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-sm-down-9-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-sm-down-9-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-sm-down-9-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-sm-down-9-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-sm-down-9-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-sm-down-span-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-10 {
    grid-column: 10;
  }
  [class*=col-].col-sm-down-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-sm-down-10-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-sm-down-10-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-sm-down-10-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-sm-down-10-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-sm-down-10-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-sm-down-10-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-sm-down-10-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-sm-down-10-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-sm-down-10-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-sm-down-10-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-sm-down-10-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-sm-down-10-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-sm-down-span-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-11 {
    grid-column: 11;
  }
  [class*=col-].col-sm-down-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-sm-down-11-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-sm-down-11-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-sm-down-11-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-sm-down-11-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-sm-down-11-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-sm-down-11-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-sm-down-11-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-sm-down-11-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-sm-down-11-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-sm-down-11-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-sm-down-11-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-sm-down-11-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-sm-down-span-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-sm-down-12 {
    grid-column: 12;
  }
  [class*=col-].col-sm-down-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-sm-down-12-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-sm-down-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-sm-down-12-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-sm-down-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-sm-down-12-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-sm-down-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-sm-down-12-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-sm-down-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-sm-down-12-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-sm-down-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-sm-down-12-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-sm-down-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-sm-down-12-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-sm-down-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-sm-down-12-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-sm-down-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-sm-down-12-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-sm-down-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-sm-down-12-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-sm-down-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-sm-down-12-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-sm-down-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-sm-down-12-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
}
@media (min-width: 660px) {
  [class*=col-].col-md-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-md-span-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-1 {
    grid-column: 1;
  }
  [class*=col-].col-md-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-md-1-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-md-1-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-md-1-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-md-1-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-md-1-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-md-1-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-md-1-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-md-1-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-md-1-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-md-1-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-md-1-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-md-1-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-md-span-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-2 {
    grid-column: 2;
  }
  [class*=col-].col-md-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-md-2-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-md-2-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-md-2-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-md-2-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-md-2-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-md-2-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-md-2-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-md-2-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-md-2-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-md-2-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-md-2-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-md-2-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-md-span-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-3 {
    grid-column: 3;
  }
  [class*=col-].col-md-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-md-3-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-md-3-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-md-3-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-md-3-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-md-3-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-md-3-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-md-3-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-md-3-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-md-3-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-md-3-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-md-3-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-md-3-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-md-span-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-4 {
    grid-column: 4;
  }
  [class*=col-].col-md-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-md-4-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-md-4-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-md-4-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-md-4-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-md-4-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-md-4-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-md-4-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-md-4-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-md-4-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-md-4-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-md-4-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-md-4-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-md-span-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-5 {
    grid-column: 5;
  }
  [class*=col-].col-md-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-md-5-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-md-5-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-md-5-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-md-5-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-md-5-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-md-5-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-md-5-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-md-5-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-md-5-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-md-5-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-md-5-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-md-5-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-md-span-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-6 {
    grid-column: 6;
  }
  [class*=col-].col-md-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-md-6-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-md-6-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-md-6-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-md-6-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-md-6-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-md-6-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-md-6-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-md-6-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-md-6-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-md-6-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-md-6-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-md-6-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-md-span-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-7 {
    grid-column: 7;
  }
  [class*=col-].col-md-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-md-7-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-md-7-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-md-7-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-md-7-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-md-7-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-md-7-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-md-7-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-md-7-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-md-7-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-md-7-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-md-7-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-md-7-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-md-span-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-8 {
    grid-column: 8;
  }
  [class*=col-].col-md-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-md-8-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-md-8-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-md-8-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-md-8-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-md-8-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-md-8-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-md-8-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-md-8-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-md-8-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-md-8-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-md-8-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-md-8-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-md-span-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-9 {
    grid-column: 9;
  }
  [class*=col-].col-md-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-md-9-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-md-9-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-md-9-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-md-9-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-md-9-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-md-9-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-md-9-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-md-9-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-md-9-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-md-9-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-md-9-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-md-9-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-md-span-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-10 {
    grid-column: 10;
  }
  [class*=col-].col-md-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-md-10-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-md-10-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-md-10-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-md-10-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-md-10-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-md-10-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-md-10-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-md-10-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-md-10-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-md-10-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-md-10-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-md-10-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-md-span-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-11 {
    grid-column: 11;
  }
  [class*=col-].col-md-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-md-11-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-md-11-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-md-11-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-md-11-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-md-11-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-md-11-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-md-11-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-md-11-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-md-11-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-md-11-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-md-11-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-md-11-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-md-span-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-md-12 {
    grid-column: 12;
  }
  [class*=col-].col-md-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-md-12-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-md-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-md-12-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-md-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-md-12-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-md-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-md-12-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-md-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-md-12-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-md-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-md-12-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-md-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-md-12-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-md-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-md-12-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-md-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-md-12-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-md-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-md-12-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-md-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-md-12-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-md-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-md-12-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 659px) {
  [class*=col-].col-md-down-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-md-down-span-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-1 {
    grid-column: 1;
  }
  [class*=col-].col-md-down-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-md-down-1-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-md-down-1-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-md-down-1-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-md-down-1-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-md-down-1-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-md-down-1-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-md-down-1-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-md-down-1-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-md-down-1-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-md-down-1-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-md-down-1-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-md-down-1-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-md-down-span-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-2 {
    grid-column: 2;
  }
  [class*=col-].col-md-down-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-md-down-2-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-md-down-2-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-md-down-2-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-md-down-2-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-md-down-2-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-md-down-2-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-md-down-2-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-md-down-2-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-md-down-2-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-md-down-2-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-md-down-2-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-md-down-2-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-md-down-span-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-3 {
    grid-column: 3;
  }
  [class*=col-].col-md-down-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-md-down-3-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-md-down-3-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-md-down-3-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-md-down-3-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-md-down-3-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-md-down-3-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-md-down-3-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-md-down-3-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-md-down-3-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-md-down-3-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-md-down-3-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-md-down-3-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-md-down-span-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-4 {
    grid-column: 4;
  }
  [class*=col-].col-md-down-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-md-down-4-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-md-down-4-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-md-down-4-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-md-down-4-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-md-down-4-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-md-down-4-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-md-down-4-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-md-down-4-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-md-down-4-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-md-down-4-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-md-down-4-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-md-down-4-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-md-down-span-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-5 {
    grid-column: 5;
  }
  [class*=col-].col-md-down-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-md-down-5-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-md-down-5-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-md-down-5-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-md-down-5-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-md-down-5-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-md-down-5-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-md-down-5-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-md-down-5-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-md-down-5-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-md-down-5-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-md-down-5-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-md-down-5-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-md-down-span-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-6 {
    grid-column: 6;
  }
  [class*=col-].col-md-down-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-md-down-6-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-md-down-6-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-md-down-6-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-md-down-6-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-md-down-6-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-md-down-6-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-md-down-6-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-md-down-6-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-md-down-6-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-md-down-6-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-md-down-6-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-md-down-6-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-md-down-span-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-7 {
    grid-column: 7;
  }
  [class*=col-].col-md-down-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-md-down-7-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-md-down-7-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-md-down-7-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-md-down-7-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-md-down-7-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-md-down-7-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-md-down-7-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-md-down-7-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-md-down-7-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-md-down-7-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-md-down-7-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-md-down-7-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-md-down-span-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-8 {
    grid-column: 8;
  }
  [class*=col-].col-md-down-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-md-down-8-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-md-down-8-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-md-down-8-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-md-down-8-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-md-down-8-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-md-down-8-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-md-down-8-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-md-down-8-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-md-down-8-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-md-down-8-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-md-down-8-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-md-down-8-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-md-down-span-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-9 {
    grid-column: 9;
  }
  [class*=col-].col-md-down-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-md-down-9-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-md-down-9-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-md-down-9-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-md-down-9-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-md-down-9-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-md-down-9-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-md-down-9-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-md-down-9-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-md-down-9-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-md-down-9-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-md-down-9-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-md-down-9-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-md-down-span-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-10 {
    grid-column: 10;
  }
  [class*=col-].col-md-down-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-md-down-10-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-md-down-10-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-md-down-10-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-md-down-10-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-md-down-10-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-md-down-10-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-md-down-10-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-md-down-10-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-md-down-10-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-md-down-10-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-md-down-10-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-md-down-10-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-md-down-span-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-11 {
    grid-column: 11;
  }
  [class*=col-].col-md-down-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-md-down-11-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-md-down-11-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-md-down-11-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-md-down-11-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-md-down-11-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-md-down-11-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-md-down-11-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-md-down-11-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-md-down-11-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-md-down-11-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-md-down-11-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-md-down-11-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-md-down-span-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-md-down-12 {
    grid-column: 12;
  }
  [class*=col-].col-md-down-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-md-down-12-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-md-down-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-md-down-12-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-md-down-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-md-down-12-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-md-down-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-md-down-12-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-md-down-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-md-down-12-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-md-down-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-md-down-12-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-md-down-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-md-down-12-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-md-down-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-md-down-12-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-md-down-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-md-down-12-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-md-down-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-md-down-12-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-md-down-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-md-down-12-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-md-down-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-md-down-12-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
}
@media (min-width: 770px) {
  [class*=col-].col-lg-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-lg-span-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-1 {
    grid-column: 1;
  }
  [class*=col-].col-lg-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-lg-1-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-lg-1-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-lg-1-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-lg-1-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-lg-1-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-lg-1-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-lg-1-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-lg-1-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-lg-1-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-lg-1-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-lg-1-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-lg-1-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-lg-span-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-2 {
    grid-column: 2;
  }
  [class*=col-].col-lg-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-lg-2-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-lg-2-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-lg-2-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-lg-2-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-lg-2-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-lg-2-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-lg-2-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-lg-2-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-lg-2-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-lg-2-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-lg-2-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-lg-2-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-lg-span-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-3 {
    grid-column: 3;
  }
  [class*=col-].col-lg-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-lg-3-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-lg-3-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-lg-3-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-lg-3-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-lg-3-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-lg-3-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-lg-3-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-lg-3-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-lg-3-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-lg-3-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-lg-3-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-lg-3-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-lg-span-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-4 {
    grid-column: 4;
  }
  [class*=col-].col-lg-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-lg-4-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-lg-4-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-lg-4-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-lg-4-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-lg-4-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-lg-4-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-lg-4-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-lg-4-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-lg-4-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-lg-4-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-lg-4-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-lg-4-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-lg-span-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-5 {
    grid-column: 5;
  }
  [class*=col-].col-lg-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-lg-5-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-lg-5-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-lg-5-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-lg-5-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-lg-5-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-lg-5-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-lg-5-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-lg-5-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-lg-5-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-lg-5-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-lg-5-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-lg-5-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-lg-span-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-6 {
    grid-column: 6;
  }
  [class*=col-].col-lg-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-lg-6-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-lg-6-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-lg-6-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-lg-6-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-lg-6-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-lg-6-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-lg-6-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-lg-6-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-lg-6-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-lg-6-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-lg-6-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-lg-6-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-lg-span-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-7 {
    grid-column: 7;
  }
  [class*=col-].col-lg-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-lg-7-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-lg-7-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-lg-7-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-lg-7-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-lg-7-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-lg-7-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-lg-7-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-lg-7-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-lg-7-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-lg-7-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-lg-7-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-lg-7-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-lg-span-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-8 {
    grid-column: 8;
  }
  [class*=col-].col-lg-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-lg-8-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-lg-8-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-lg-8-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-lg-8-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-lg-8-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-lg-8-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-lg-8-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-lg-8-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-lg-8-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-lg-8-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-lg-8-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-lg-8-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-lg-span-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-9 {
    grid-column: 9;
  }
  [class*=col-].col-lg-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-lg-9-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-lg-9-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-lg-9-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-lg-9-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-lg-9-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-lg-9-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-lg-9-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-lg-9-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-lg-9-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-lg-9-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-lg-9-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-lg-9-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-lg-span-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-10 {
    grid-column: 10;
  }
  [class*=col-].col-lg-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-lg-10-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-lg-10-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-lg-10-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-lg-10-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-lg-10-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-lg-10-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-lg-10-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-lg-10-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-lg-10-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-lg-10-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-lg-10-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-lg-10-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-lg-span-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-11 {
    grid-column: 11;
  }
  [class*=col-].col-lg-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-lg-11-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-lg-11-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-lg-11-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-lg-11-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-lg-11-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-lg-11-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-lg-11-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-lg-11-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-lg-11-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-lg-11-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-lg-11-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-lg-11-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-lg-span-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-lg-12 {
    grid-column: 12;
  }
  [class*=col-].col-lg-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-lg-12-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-lg-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-lg-12-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-lg-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-lg-12-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-lg-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-lg-12-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-lg-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-lg-12-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-lg-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-lg-12-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-lg-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-lg-12-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-lg-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-lg-12-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-lg-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-lg-12-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-lg-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-lg-12-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-lg-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-lg-12-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-lg-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-lg-12-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 769px) {
  [class*=col-].col-lg-down-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-lg-down-span-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-1 {
    grid-column: 1;
  }
  [class*=col-].col-lg-down-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-lg-down-1-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-lg-down-1-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-lg-down-1-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-lg-down-1-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-lg-down-1-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-lg-down-1-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-lg-down-1-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-lg-down-1-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-lg-down-1-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-lg-down-1-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-lg-down-1-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-lg-down-1-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-lg-down-span-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-2 {
    grid-column: 2;
  }
  [class*=col-].col-lg-down-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-lg-down-2-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-lg-down-2-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-lg-down-2-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-lg-down-2-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-lg-down-2-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-lg-down-2-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-lg-down-2-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-lg-down-2-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-lg-down-2-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-lg-down-2-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-lg-down-2-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-lg-down-2-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-lg-down-span-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-3 {
    grid-column: 3;
  }
  [class*=col-].col-lg-down-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-lg-down-3-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-lg-down-3-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-lg-down-3-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-lg-down-3-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-lg-down-3-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-lg-down-3-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-lg-down-3-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-lg-down-3-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-lg-down-3-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-lg-down-3-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-lg-down-3-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-lg-down-3-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-lg-down-span-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-4 {
    grid-column: 4;
  }
  [class*=col-].col-lg-down-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-lg-down-4-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-lg-down-4-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-lg-down-4-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-lg-down-4-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-lg-down-4-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-lg-down-4-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-lg-down-4-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-lg-down-4-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-lg-down-4-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-lg-down-4-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-lg-down-4-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-lg-down-4-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-lg-down-span-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-5 {
    grid-column: 5;
  }
  [class*=col-].col-lg-down-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-lg-down-5-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-lg-down-5-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-lg-down-5-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-lg-down-5-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-lg-down-5-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-lg-down-5-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-lg-down-5-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-lg-down-5-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-lg-down-5-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-lg-down-5-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-lg-down-5-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-lg-down-5-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-lg-down-span-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-6 {
    grid-column: 6;
  }
  [class*=col-].col-lg-down-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-lg-down-6-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-lg-down-6-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-lg-down-6-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-lg-down-6-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-lg-down-6-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-lg-down-6-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-lg-down-6-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-lg-down-6-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-lg-down-6-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-lg-down-6-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-lg-down-6-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-lg-down-6-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-lg-down-span-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-7 {
    grid-column: 7;
  }
  [class*=col-].col-lg-down-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-lg-down-7-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-lg-down-7-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-lg-down-7-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-lg-down-7-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-lg-down-7-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-lg-down-7-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-lg-down-7-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-lg-down-7-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-lg-down-7-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-lg-down-7-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-lg-down-7-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-lg-down-7-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-lg-down-span-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-8 {
    grid-column: 8;
  }
  [class*=col-].col-lg-down-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-lg-down-8-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-lg-down-8-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-lg-down-8-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-lg-down-8-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-lg-down-8-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-lg-down-8-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-lg-down-8-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-lg-down-8-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-lg-down-8-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-lg-down-8-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-lg-down-8-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-lg-down-8-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-lg-down-span-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-9 {
    grid-column: 9;
  }
  [class*=col-].col-lg-down-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-lg-down-9-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-lg-down-9-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-lg-down-9-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-lg-down-9-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-lg-down-9-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-lg-down-9-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-lg-down-9-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-lg-down-9-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-lg-down-9-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-lg-down-9-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-lg-down-9-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-lg-down-9-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-lg-down-span-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-10 {
    grid-column: 10;
  }
  [class*=col-].col-lg-down-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-lg-down-10-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-lg-down-10-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-lg-down-10-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-lg-down-10-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-lg-down-10-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-lg-down-10-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-lg-down-10-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-lg-down-10-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-lg-down-10-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-lg-down-10-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-lg-down-10-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-lg-down-10-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-lg-down-span-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-11 {
    grid-column: 11;
  }
  [class*=col-].col-lg-down-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-lg-down-11-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-lg-down-11-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-lg-down-11-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-lg-down-11-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-lg-down-11-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-lg-down-11-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-lg-down-11-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-lg-down-11-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-lg-down-11-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-lg-down-11-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-lg-down-11-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-lg-down-11-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-lg-down-span-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-lg-down-12 {
    grid-column: 12;
  }
  [class*=col-].col-lg-down-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-lg-down-12-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-lg-down-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-lg-down-12-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-lg-down-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-lg-down-12-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-lg-down-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-lg-down-12-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-lg-down-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-lg-down-12-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-lg-down-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-lg-down-12-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-lg-down-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-lg-down-12-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-lg-down-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-lg-down-12-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-lg-down-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-lg-down-12-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-lg-down-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-lg-down-12-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-lg-down-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-lg-down-12-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-lg-down-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-lg-down-12-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
}
@media (min-width: 1024px) {
  [class*=col-].col-xl-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-xl-span-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-1 {
    grid-column: 1;
  }
  [class*=col-].col-xl-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-xl-1-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-xl-1-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-xl-1-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-xl-1-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-xl-1-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-xl-1-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-xl-1-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-xl-1-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-xl-1-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-xl-1-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-xl-1-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-xl-1-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-xl-span-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-2 {
    grid-column: 2;
  }
  [class*=col-].col-xl-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-xl-2-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-xl-2-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-xl-2-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-xl-2-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-xl-2-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-xl-2-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-xl-2-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-xl-2-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-xl-2-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-xl-2-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-xl-2-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-xl-2-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-xl-span-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-3 {
    grid-column: 3;
  }
  [class*=col-].col-xl-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-xl-3-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-xl-3-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-xl-3-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-xl-3-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-xl-3-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-xl-3-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-xl-3-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-xl-3-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-xl-3-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-xl-3-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-xl-3-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-xl-3-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-xl-span-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-4 {
    grid-column: 4;
  }
  [class*=col-].col-xl-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-xl-4-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-xl-4-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-xl-4-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-xl-4-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-xl-4-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-xl-4-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-xl-4-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-xl-4-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-xl-4-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-xl-4-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-xl-4-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-xl-4-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-xl-span-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-5 {
    grid-column: 5;
  }
  [class*=col-].col-xl-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-xl-5-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-xl-5-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-xl-5-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-xl-5-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-xl-5-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-xl-5-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-xl-5-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-xl-5-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-xl-5-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-xl-5-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-xl-5-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-xl-5-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-xl-span-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-6 {
    grid-column: 6;
  }
  [class*=col-].col-xl-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-xl-6-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-xl-6-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-xl-6-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-xl-6-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-xl-6-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-xl-6-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-xl-6-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-xl-6-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-xl-6-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-xl-6-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-xl-6-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-xl-6-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-xl-span-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-7 {
    grid-column: 7;
  }
  [class*=col-].col-xl-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-xl-7-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-xl-7-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-xl-7-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-xl-7-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-xl-7-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-xl-7-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-xl-7-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-xl-7-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-xl-7-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-xl-7-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-xl-7-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-xl-7-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-xl-span-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-8 {
    grid-column: 8;
  }
  [class*=col-].col-xl-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-xl-8-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-xl-8-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-xl-8-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-xl-8-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-xl-8-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-xl-8-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-xl-8-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-xl-8-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-xl-8-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-xl-8-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-xl-8-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-xl-8-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-xl-span-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-9 {
    grid-column: 9;
  }
  [class*=col-].col-xl-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-xl-9-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-xl-9-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-xl-9-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-xl-9-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-xl-9-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-xl-9-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-xl-9-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-xl-9-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-xl-9-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-xl-9-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-xl-9-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-xl-9-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-xl-span-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-10 {
    grid-column: 10;
  }
  [class*=col-].col-xl-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-xl-10-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-xl-10-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-xl-10-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-xl-10-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-xl-10-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-xl-10-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-xl-10-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-xl-10-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-xl-10-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-xl-10-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-xl-10-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-xl-10-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-xl-span-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-11 {
    grid-column: 11;
  }
  [class*=col-].col-xl-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-xl-11-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-xl-11-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-xl-11-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-xl-11-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-xl-11-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-xl-11-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-xl-11-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-xl-11-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-xl-11-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-xl-11-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-xl-11-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-xl-11-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-xl-span-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xl-12 {
    grid-column: 12;
  }
  [class*=col-].col-xl-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-xl-12-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xl-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-xl-12-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xl-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-xl-12-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xl-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-xl-12-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xl-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-xl-12-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xl-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-xl-12-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xl-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-xl-12-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xl-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-xl-12-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xl-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-xl-12-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xl-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-xl-12-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xl-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-xl-12-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xl-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-xl-12-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 1023px) {
  [class*=col-].col-xl-down-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-xl-down-span-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-1 {
    grid-column: 1;
  }
  [class*=col-].col-xl-down-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-xl-down-1-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-xl-down-1-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-xl-down-1-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-xl-down-1-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-xl-down-1-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-xl-down-1-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-xl-down-1-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-xl-down-1-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-xl-down-1-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-xl-down-1-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-xl-down-1-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-xl-down-1-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-xl-down-span-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-2 {
    grid-column: 2;
  }
  [class*=col-].col-xl-down-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-xl-down-2-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-xl-down-2-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-xl-down-2-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-xl-down-2-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-xl-down-2-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-xl-down-2-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-xl-down-2-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-xl-down-2-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-xl-down-2-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-xl-down-2-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-xl-down-2-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-xl-down-2-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-xl-down-span-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-3 {
    grid-column: 3;
  }
  [class*=col-].col-xl-down-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-xl-down-3-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-xl-down-3-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-xl-down-3-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-xl-down-3-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-xl-down-3-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-xl-down-3-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-xl-down-3-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-xl-down-3-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-xl-down-3-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-xl-down-3-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-xl-down-3-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-xl-down-3-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-xl-down-span-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-4 {
    grid-column: 4;
  }
  [class*=col-].col-xl-down-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-xl-down-4-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-xl-down-4-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-xl-down-4-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-xl-down-4-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-xl-down-4-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-xl-down-4-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-xl-down-4-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-xl-down-4-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-xl-down-4-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-xl-down-4-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-xl-down-4-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-xl-down-4-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-xl-down-span-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-5 {
    grid-column: 5;
  }
  [class*=col-].col-xl-down-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-xl-down-5-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-xl-down-5-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-xl-down-5-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-xl-down-5-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-xl-down-5-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-xl-down-5-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-xl-down-5-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-xl-down-5-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-xl-down-5-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-xl-down-5-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-xl-down-5-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-xl-down-5-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-xl-down-span-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-6 {
    grid-column: 6;
  }
  [class*=col-].col-xl-down-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-xl-down-6-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-xl-down-6-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-xl-down-6-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-xl-down-6-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-xl-down-6-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-xl-down-6-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-xl-down-6-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-xl-down-6-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-xl-down-6-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-xl-down-6-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-xl-down-6-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-xl-down-6-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-xl-down-span-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-7 {
    grid-column: 7;
  }
  [class*=col-].col-xl-down-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-xl-down-7-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-xl-down-7-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-xl-down-7-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-xl-down-7-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-xl-down-7-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-xl-down-7-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-xl-down-7-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-xl-down-7-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-xl-down-7-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-xl-down-7-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-xl-down-7-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-xl-down-7-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-xl-down-span-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-8 {
    grid-column: 8;
  }
  [class*=col-].col-xl-down-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-xl-down-8-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-xl-down-8-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-xl-down-8-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-xl-down-8-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-xl-down-8-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-xl-down-8-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-xl-down-8-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-xl-down-8-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-xl-down-8-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-xl-down-8-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-xl-down-8-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-xl-down-8-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-xl-down-span-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-9 {
    grid-column: 9;
  }
  [class*=col-].col-xl-down-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-xl-down-9-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-xl-down-9-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-xl-down-9-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-xl-down-9-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-xl-down-9-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-xl-down-9-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-xl-down-9-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-xl-down-9-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-xl-down-9-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-xl-down-9-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-xl-down-9-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-xl-down-9-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-xl-down-span-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-10 {
    grid-column: 10;
  }
  [class*=col-].col-xl-down-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-xl-down-10-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-xl-down-10-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-xl-down-10-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-xl-down-10-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-xl-down-10-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-xl-down-10-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-xl-down-10-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-xl-down-10-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-xl-down-10-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-xl-down-10-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-xl-down-10-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-xl-down-10-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-xl-down-span-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-11 {
    grid-column: 11;
  }
  [class*=col-].col-xl-down-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-xl-down-11-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-xl-down-11-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-xl-down-11-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-xl-down-11-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-xl-down-11-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-xl-down-11-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-xl-down-11-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-xl-down-11-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-xl-down-11-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-xl-down-11-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-xl-down-11-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-xl-down-11-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-xl-down-span-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xl-down-12 {
    grid-column: 12;
  }
  [class*=col-].col-xl-down-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-xl-down-12-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xl-down-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-xl-down-12-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xl-down-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-xl-down-12-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xl-down-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-xl-down-12-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xl-down-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-xl-down-12-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xl-down-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-xl-down-12-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xl-down-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-xl-down-12-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xl-down-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-xl-down-12-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xl-down-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-xl-down-12-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xl-down-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-xl-down-12-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xl-down-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-xl-down-12-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xl-down-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-xl-down-12-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
}
@media (min-width: 1440px) {
  [class*=col-].col-xxl-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-xxl-span-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-1 {
    grid-column: 1;
  }
  [class*=col-].col-xxl-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-xxl-1-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-xxl-1-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-xxl-1-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-xxl-1-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-xxl-1-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-xxl-1-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-xxl-1-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-xxl-1-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-xxl-1-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-xxl-1-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-xxl-1-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-xxl-1-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-xxl-span-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-2 {
    grid-column: 2;
  }
  [class*=col-].col-xxl-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-xxl-2-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-xxl-2-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-xxl-2-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-xxl-2-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-xxl-2-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-xxl-2-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-xxl-2-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-xxl-2-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-xxl-2-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-xxl-2-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-xxl-2-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-xxl-2-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-xxl-span-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-3 {
    grid-column: 3;
  }
  [class*=col-].col-xxl-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-xxl-3-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-xxl-3-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-xxl-3-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-xxl-3-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-xxl-3-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-xxl-3-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-xxl-3-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-xxl-3-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-xxl-3-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-xxl-3-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-xxl-3-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-xxl-3-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-xxl-span-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-4 {
    grid-column: 4;
  }
  [class*=col-].col-xxl-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-xxl-4-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-xxl-4-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-xxl-4-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-xxl-4-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-xxl-4-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-xxl-4-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-xxl-4-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-xxl-4-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-xxl-4-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-xxl-4-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-xxl-4-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-xxl-4-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-xxl-span-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-5 {
    grid-column: 5;
  }
  [class*=col-].col-xxl-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-xxl-5-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-xxl-5-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-xxl-5-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-xxl-5-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-xxl-5-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-xxl-5-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-xxl-5-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-xxl-5-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-xxl-5-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-xxl-5-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-xxl-5-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-xxl-5-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-xxl-span-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-6 {
    grid-column: 6;
  }
  [class*=col-].col-xxl-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-xxl-6-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-xxl-6-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-xxl-6-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-xxl-6-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-xxl-6-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-xxl-6-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-xxl-6-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-xxl-6-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-xxl-6-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-xxl-6-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-xxl-6-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-xxl-6-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-xxl-span-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-7 {
    grid-column: 7;
  }
  [class*=col-].col-xxl-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-xxl-7-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-xxl-7-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-xxl-7-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-xxl-7-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-xxl-7-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-xxl-7-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-xxl-7-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-xxl-7-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-xxl-7-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-xxl-7-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-xxl-7-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-xxl-7-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-xxl-span-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-8 {
    grid-column: 8;
  }
  [class*=col-].col-xxl-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-xxl-8-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-xxl-8-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-xxl-8-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-xxl-8-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-xxl-8-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-xxl-8-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-xxl-8-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-xxl-8-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-xxl-8-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-xxl-8-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-xxl-8-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-xxl-8-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-xxl-span-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-9 {
    grid-column: 9;
  }
  [class*=col-].col-xxl-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-xxl-9-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-xxl-9-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-xxl-9-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-xxl-9-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-xxl-9-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-xxl-9-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-xxl-9-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-xxl-9-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-xxl-9-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-xxl-9-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-xxl-9-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-xxl-9-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-xxl-span-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-10 {
    grid-column: 10;
  }
  [class*=col-].col-xxl-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-xxl-10-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-xxl-10-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-xxl-10-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-xxl-10-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-xxl-10-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-xxl-10-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-xxl-10-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-xxl-10-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-xxl-10-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-xxl-10-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-xxl-10-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-xxl-10-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-xxl-span-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-11 {
    grid-column: 11;
  }
  [class*=col-].col-xxl-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-xxl-11-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-xxl-11-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-xxl-11-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-xxl-11-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-xxl-11-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-xxl-11-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-xxl-11-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-xxl-11-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-xxl-11-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-xxl-11-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-xxl-11-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-xxl-11-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-xxl-span-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
  [class*=col-].col-xxl-12 {
    grid-column: 12;
  }
  [class*=col-].col-xxl-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-xxl-12-1[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  [class*=col-].col-xxl-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-xxl-12-2[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  [class*=col-].col-xxl-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-xxl-12-3[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }
  [class*=col-].col-xxl-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-xxl-12-4[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  [class*=col-].col-xxl-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-xxl-12-5[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
  }
  [class*=col-].col-xxl-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-xxl-12-6[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  [class*=col-].col-xxl-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-xxl-12-7[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
  }
  [class*=col-].col-xxl-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-xxl-12-8[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
  }
  [class*=col-].col-xxl-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-xxl-12-9[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
  }
  [class*=col-].col-xxl-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-xxl-12-10[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
  }
  [class*=col-].col-xxl-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-xxl-12-11[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
  }
  [class*=col-].col-xxl-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-xxl-12-12[class*=container] {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 1439px) {
  [class*=col-].col-xxl-down-span-1 {
    grid-column: span 1;
  }
  [class*=col-].col-xxl-down-span-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-1 {
    grid-column: 1;
  }
  [class*=col-].col-xxl-down-1-1 {
    grid-column: 1/span 1;
  }
  [class*=col-].col-xxl-down-1-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-1-2 {
    grid-column: 1/span 2;
  }
  [class*=col-].col-xxl-down-1-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-1-3 {
    grid-column: 1/span 3;
  }
  [class*=col-].col-xxl-down-1-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-1-4 {
    grid-column: 1/span 4;
  }
  [class*=col-].col-xxl-down-1-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-1-5 {
    grid-column: 1/span 5;
  }
  [class*=col-].col-xxl-down-1-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-1-6 {
    grid-column: 1/span 6;
  }
  [class*=col-].col-xxl-down-1-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-1-7 {
    grid-column: 1/span 7;
  }
  [class*=col-].col-xxl-down-1-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-1-8 {
    grid-column: 1/span 8;
  }
  [class*=col-].col-xxl-down-1-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-1-9 {
    grid-column: 1/span 9;
  }
  [class*=col-].col-xxl-down-1-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-1-10 {
    grid-column: 1/span 10;
  }
  [class*=col-].col-xxl-down-1-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-1-11 {
    grid-column: 1/span 11;
  }
  [class*=col-].col-xxl-down-1-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-1-12 {
    grid-column: 1/span 12;
  }
  [class*=col-].col-xxl-down-1-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-2 {
    grid-column: span 2;
  }
  [class*=col-].col-xxl-down-span-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-2 {
    grid-column: 2;
  }
  [class*=col-].col-xxl-down-2-1 {
    grid-column: 2/span 1;
  }
  [class*=col-].col-xxl-down-2-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-2-2 {
    grid-column: 2/span 2;
  }
  [class*=col-].col-xxl-down-2-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-2-3 {
    grid-column: 2/span 3;
  }
  [class*=col-].col-xxl-down-2-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-2-4 {
    grid-column: 2/span 4;
  }
  [class*=col-].col-xxl-down-2-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-2-5 {
    grid-column: 2/span 5;
  }
  [class*=col-].col-xxl-down-2-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-2-6 {
    grid-column: 2/span 6;
  }
  [class*=col-].col-xxl-down-2-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-2-7 {
    grid-column: 2/span 7;
  }
  [class*=col-].col-xxl-down-2-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-2-8 {
    grid-column: 2/span 8;
  }
  [class*=col-].col-xxl-down-2-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-2-9 {
    grid-column: 2/span 9;
  }
  [class*=col-].col-xxl-down-2-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-2-10 {
    grid-column: 2/span 10;
  }
  [class*=col-].col-xxl-down-2-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-2-11 {
    grid-column: 2/span 11;
  }
  [class*=col-].col-xxl-down-2-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-2-12 {
    grid-column: 2/span 12;
  }
  [class*=col-].col-xxl-down-2-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-3 {
    grid-column: span 3;
  }
  [class*=col-].col-xxl-down-span-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-3 {
    grid-column: 3;
  }
  [class*=col-].col-xxl-down-3-1 {
    grid-column: 3/span 1;
  }
  [class*=col-].col-xxl-down-3-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-3-2 {
    grid-column: 3/span 2;
  }
  [class*=col-].col-xxl-down-3-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-3-3 {
    grid-column: 3/span 3;
  }
  [class*=col-].col-xxl-down-3-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-3-4 {
    grid-column: 3/span 4;
  }
  [class*=col-].col-xxl-down-3-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-3-5 {
    grid-column: 3/span 5;
  }
  [class*=col-].col-xxl-down-3-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-3-6 {
    grid-column: 3/span 6;
  }
  [class*=col-].col-xxl-down-3-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-3-7 {
    grid-column: 3/span 7;
  }
  [class*=col-].col-xxl-down-3-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-3-8 {
    grid-column: 3/span 8;
  }
  [class*=col-].col-xxl-down-3-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-3-9 {
    grid-column: 3/span 9;
  }
  [class*=col-].col-xxl-down-3-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-3-10 {
    grid-column: 3/span 10;
  }
  [class*=col-].col-xxl-down-3-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-3-11 {
    grid-column: 3/span 11;
  }
  [class*=col-].col-xxl-down-3-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-3-12 {
    grid-column: 3/span 12;
  }
  [class*=col-].col-xxl-down-3-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-4 {
    grid-column: span 4;
  }
  [class*=col-].col-xxl-down-span-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-4 {
    grid-column: 4;
  }
  [class*=col-].col-xxl-down-4-1 {
    grid-column: 4/span 1;
  }
  [class*=col-].col-xxl-down-4-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-4-2 {
    grid-column: 4/span 2;
  }
  [class*=col-].col-xxl-down-4-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-4-3 {
    grid-column: 4/span 3;
  }
  [class*=col-].col-xxl-down-4-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-4-4 {
    grid-column: 4/span 4;
  }
  [class*=col-].col-xxl-down-4-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-4-5 {
    grid-column: 4/span 5;
  }
  [class*=col-].col-xxl-down-4-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-4-6 {
    grid-column: 4/span 6;
  }
  [class*=col-].col-xxl-down-4-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-4-7 {
    grid-column: 4/span 7;
  }
  [class*=col-].col-xxl-down-4-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-4-8 {
    grid-column: 4/span 8;
  }
  [class*=col-].col-xxl-down-4-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-4-9 {
    grid-column: 4/span 9;
  }
  [class*=col-].col-xxl-down-4-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-4-10 {
    grid-column: 4/span 10;
  }
  [class*=col-].col-xxl-down-4-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-4-11 {
    grid-column: 4/span 11;
  }
  [class*=col-].col-xxl-down-4-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-4-12 {
    grid-column: 4/span 12;
  }
  [class*=col-].col-xxl-down-4-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-5 {
    grid-column: span 5;
  }
  [class*=col-].col-xxl-down-span-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-5 {
    grid-column: 5;
  }
  [class*=col-].col-xxl-down-5-1 {
    grid-column: 5/span 1;
  }
  [class*=col-].col-xxl-down-5-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-5-2 {
    grid-column: 5/span 2;
  }
  [class*=col-].col-xxl-down-5-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-5-3 {
    grid-column: 5/span 3;
  }
  [class*=col-].col-xxl-down-5-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-5-4 {
    grid-column: 5/span 4;
  }
  [class*=col-].col-xxl-down-5-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-5-5 {
    grid-column: 5/span 5;
  }
  [class*=col-].col-xxl-down-5-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-5-6 {
    grid-column: 5/span 6;
  }
  [class*=col-].col-xxl-down-5-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-5-7 {
    grid-column: 5/span 7;
  }
  [class*=col-].col-xxl-down-5-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-5-8 {
    grid-column: 5/span 8;
  }
  [class*=col-].col-xxl-down-5-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-5-9 {
    grid-column: 5/span 9;
  }
  [class*=col-].col-xxl-down-5-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-5-10 {
    grid-column: 5/span 10;
  }
  [class*=col-].col-xxl-down-5-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-5-11 {
    grid-column: 5/span 11;
  }
  [class*=col-].col-xxl-down-5-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-5-12 {
    grid-column: 5/span 12;
  }
  [class*=col-].col-xxl-down-5-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-6 {
    grid-column: span 6;
  }
  [class*=col-].col-xxl-down-span-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-6 {
    grid-column: 6;
  }
  [class*=col-].col-xxl-down-6-1 {
    grid-column: 6/span 1;
  }
  [class*=col-].col-xxl-down-6-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-6-2 {
    grid-column: 6/span 2;
  }
  [class*=col-].col-xxl-down-6-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-6-3 {
    grid-column: 6/span 3;
  }
  [class*=col-].col-xxl-down-6-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-6-4 {
    grid-column: 6/span 4;
  }
  [class*=col-].col-xxl-down-6-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-6-5 {
    grid-column: 6/span 5;
  }
  [class*=col-].col-xxl-down-6-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-6-6 {
    grid-column: 6/span 6;
  }
  [class*=col-].col-xxl-down-6-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-6-7 {
    grid-column: 6/span 7;
  }
  [class*=col-].col-xxl-down-6-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-6-8 {
    grid-column: 6/span 8;
  }
  [class*=col-].col-xxl-down-6-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-6-9 {
    grid-column: 6/span 9;
  }
  [class*=col-].col-xxl-down-6-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-6-10 {
    grid-column: 6/span 10;
  }
  [class*=col-].col-xxl-down-6-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-6-11 {
    grid-column: 6/span 11;
  }
  [class*=col-].col-xxl-down-6-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-6-12 {
    grid-column: 6/span 12;
  }
  [class*=col-].col-xxl-down-6-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-7 {
    grid-column: span 7;
  }
  [class*=col-].col-xxl-down-span-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-7 {
    grid-column: 7;
  }
  [class*=col-].col-xxl-down-7-1 {
    grid-column: 7/span 1;
  }
  [class*=col-].col-xxl-down-7-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-7-2 {
    grid-column: 7/span 2;
  }
  [class*=col-].col-xxl-down-7-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-7-3 {
    grid-column: 7/span 3;
  }
  [class*=col-].col-xxl-down-7-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-7-4 {
    grid-column: 7/span 4;
  }
  [class*=col-].col-xxl-down-7-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-7-5 {
    grid-column: 7/span 5;
  }
  [class*=col-].col-xxl-down-7-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-7-6 {
    grid-column: 7/span 6;
  }
  [class*=col-].col-xxl-down-7-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-7-7 {
    grid-column: 7/span 7;
  }
  [class*=col-].col-xxl-down-7-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-7-8 {
    grid-column: 7/span 8;
  }
  [class*=col-].col-xxl-down-7-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-7-9 {
    grid-column: 7/span 9;
  }
  [class*=col-].col-xxl-down-7-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-7-10 {
    grid-column: 7/span 10;
  }
  [class*=col-].col-xxl-down-7-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-7-11 {
    grid-column: 7/span 11;
  }
  [class*=col-].col-xxl-down-7-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-7-12 {
    grid-column: 7/span 12;
  }
  [class*=col-].col-xxl-down-7-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-8 {
    grid-column: span 8;
  }
  [class*=col-].col-xxl-down-span-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-8 {
    grid-column: 8;
  }
  [class*=col-].col-xxl-down-8-1 {
    grid-column: 8/span 1;
  }
  [class*=col-].col-xxl-down-8-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-8-2 {
    grid-column: 8/span 2;
  }
  [class*=col-].col-xxl-down-8-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-8-3 {
    grid-column: 8/span 3;
  }
  [class*=col-].col-xxl-down-8-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-8-4 {
    grid-column: 8/span 4;
  }
  [class*=col-].col-xxl-down-8-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-8-5 {
    grid-column: 8/span 5;
  }
  [class*=col-].col-xxl-down-8-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-8-6 {
    grid-column: 8/span 6;
  }
  [class*=col-].col-xxl-down-8-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-8-7 {
    grid-column: 8/span 7;
  }
  [class*=col-].col-xxl-down-8-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-8-8 {
    grid-column: 8/span 8;
  }
  [class*=col-].col-xxl-down-8-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-8-9 {
    grid-column: 8/span 9;
  }
  [class*=col-].col-xxl-down-8-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-8-10 {
    grid-column: 8/span 10;
  }
  [class*=col-].col-xxl-down-8-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-8-11 {
    grid-column: 8/span 11;
  }
  [class*=col-].col-xxl-down-8-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-8-12 {
    grid-column: 8/span 12;
  }
  [class*=col-].col-xxl-down-8-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-9 {
    grid-column: span 9;
  }
  [class*=col-].col-xxl-down-span-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-9 {
    grid-column: 9;
  }
  [class*=col-].col-xxl-down-9-1 {
    grid-column: 9/span 1;
  }
  [class*=col-].col-xxl-down-9-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-9-2 {
    grid-column: 9/span 2;
  }
  [class*=col-].col-xxl-down-9-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-9-3 {
    grid-column: 9/span 3;
  }
  [class*=col-].col-xxl-down-9-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-9-4 {
    grid-column: 9/span 4;
  }
  [class*=col-].col-xxl-down-9-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-9-5 {
    grid-column: 9/span 5;
  }
  [class*=col-].col-xxl-down-9-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-9-6 {
    grid-column: 9/span 6;
  }
  [class*=col-].col-xxl-down-9-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-9-7 {
    grid-column: 9/span 7;
  }
  [class*=col-].col-xxl-down-9-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-9-8 {
    grid-column: 9/span 8;
  }
  [class*=col-].col-xxl-down-9-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-9-9 {
    grid-column: 9/span 9;
  }
  [class*=col-].col-xxl-down-9-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-9-10 {
    grid-column: 9/span 10;
  }
  [class*=col-].col-xxl-down-9-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-9-11 {
    grid-column: 9/span 11;
  }
  [class*=col-].col-xxl-down-9-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-9-12 {
    grid-column: 9/span 12;
  }
  [class*=col-].col-xxl-down-9-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-10 {
    grid-column: span 10;
  }
  [class*=col-].col-xxl-down-span-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-10 {
    grid-column: 10;
  }
  [class*=col-].col-xxl-down-10-1 {
    grid-column: 10/span 1;
  }
  [class*=col-].col-xxl-down-10-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-10-2 {
    grid-column: 10/span 2;
  }
  [class*=col-].col-xxl-down-10-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-10-3 {
    grid-column: 10/span 3;
  }
  [class*=col-].col-xxl-down-10-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-10-4 {
    grid-column: 10/span 4;
  }
  [class*=col-].col-xxl-down-10-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-10-5 {
    grid-column: 10/span 5;
  }
  [class*=col-].col-xxl-down-10-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-10-6 {
    grid-column: 10/span 6;
  }
  [class*=col-].col-xxl-down-10-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-10-7 {
    grid-column: 10/span 7;
  }
  [class*=col-].col-xxl-down-10-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-10-8 {
    grid-column: 10/span 8;
  }
  [class*=col-].col-xxl-down-10-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-10-9 {
    grid-column: 10/span 9;
  }
  [class*=col-].col-xxl-down-10-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-10-10 {
    grid-column: 10/span 10;
  }
  [class*=col-].col-xxl-down-10-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-10-11 {
    grid-column: 10/span 11;
  }
  [class*=col-].col-xxl-down-10-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-10-12 {
    grid-column: 10/span 12;
  }
  [class*=col-].col-xxl-down-10-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-11 {
    grid-column: span 11;
  }
  [class*=col-].col-xxl-down-span-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-11 {
    grid-column: 11;
  }
  [class*=col-].col-xxl-down-11-1 {
    grid-column: 11/span 1;
  }
  [class*=col-].col-xxl-down-11-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-11-2 {
    grid-column: 11/span 2;
  }
  [class*=col-].col-xxl-down-11-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-11-3 {
    grid-column: 11/span 3;
  }
  [class*=col-].col-xxl-down-11-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-11-4 {
    grid-column: 11/span 4;
  }
  [class*=col-].col-xxl-down-11-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-11-5 {
    grid-column: 11/span 5;
  }
  [class*=col-].col-xxl-down-11-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-11-6 {
    grid-column: 11/span 6;
  }
  [class*=col-].col-xxl-down-11-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-11-7 {
    grid-column: 11/span 7;
  }
  [class*=col-].col-xxl-down-11-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-11-8 {
    grid-column: 11/span 8;
  }
  [class*=col-].col-xxl-down-11-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-11-9 {
    grid-column: 11/span 9;
  }
  [class*=col-].col-xxl-down-11-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-11-10 {
    grid-column: 11/span 10;
  }
  [class*=col-].col-xxl-down-11-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-11-11 {
    grid-column: 11/span 11;
  }
  [class*=col-].col-xxl-down-11-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-11-12 {
    grid-column: 11/span 12;
  }
  [class*=col-].col-xxl-down-11-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-span-12 {
    grid-column: span 12;
  }
  [class*=col-].col-xxl-down-span-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
  [class*=col-].col-xxl-down-12 {
    grid-column: 12;
  }
  [class*=col-].col-xxl-down-12-1 {
    grid-column: 12/span 1;
  }
  [class*=col-].col-xxl-down-12-1.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(1, 1fr);
    --columns: 1 !important;
  }
  [class*=col-].col-xxl-down-12-2 {
    grid-column: 12/span 2;
  }
  [class*=col-].col-xxl-down-12-2.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(2, 1fr);
    --columns: 2 !important;
  }
  [class*=col-].col-xxl-down-12-3 {
    grid-column: 12/span 3;
  }
  [class*=col-].col-xxl-down-12-3.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(3, 1fr);
    --columns: 3 !important;
  }
  [class*=col-].col-xxl-down-12-4 {
    grid-column: 12/span 4;
  }
  [class*=col-].col-xxl-down-12-4.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
    --columns: 4 !important;
  }
  [class*=col-].col-xxl-down-12-5 {
    grid-column: 12/span 5;
  }
  [class*=col-].col-xxl-down-12-5.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(5, 1fr);
    --columns: 5 !important;
  }
  [class*=col-].col-xxl-down-12-6 {
    grid-column: 12/span 6;
  }
  [class*=col-].col-xxl-down-12-6.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(6, 1fr);
    --columns: 6 !important;
  }
  [class*=col-].col-xxl-down-12-7 {
    grid-column: 12/span 7;
  }
  [class*=col-].col-xxl-down-12-7.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(7, 1fr);
    --columns: 7 !important;
  }
  [class*=col-].col-xxl-down-12-8 {
    grid-column: 12/span 8;
  }
  [class*=col-].col-xxl-down-12-8.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(8, 1fr);
    --columns: 8 !important;
  }
  [class*=col-].col-xxl-down-12-9 {
    grid-column: 12/span 9;
  }
  [class*=col-].col-xxl-down-12-9.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(9, 1fr);
    --columns: 9 !important;
  }
  [class*=col-].col-xxl-down-12-10 {
    grid-column: 12/span 10;
  }
  [class*=col-].col-xxl-down-12-10.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(10, 1fr);
    --columns: 10 !important;
  }
  [class*=col-].col-xxl-down-12-11 {
    grid-column: 12/span 11;
  }
  [class*=col-].col-xxl-down-12-11.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(11, 1fr);
    --columns: 11 !important;
  }
  [class*=col-].col-xxl-down-12-12 {
    grid-column: 12/span 12;
  }
  [class*=col-].col-xxl-down-12-12.container {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(12, 1fr);
    --columns: 12 !important;
  }
}

.ukraine {
  background-color: #0057b7;
  position: fixed;
  height: 20px;
  width: 30px;
  display: block;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}
.ukraine::after {
  content: "";
  background-color: #ffd700;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
}

.GridHelper {
  display: none;
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.3;
  pointer-events: none;
  z-index: 99999;
  align-content: stretch;
}
.GridHelper > div {
  border-left: 1px solid #0ff;
  border-right: 1px solid #0ff;
  height: 100%;
  background-color: transparent;
}

a:not(.disabled):hover {
  font-style: italic;
}

a.disabled {
  text-decoration: line-through !important;
  cursor: default;
}

body {
  background-color: #000;
  color: #fcff57;
  font-family: "Suisse Int'l", "Helvetica", "Arial", sans-serif;
}

header.header {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}
header.header > * {
  pointer-events: all;
}
body.ui-hidden header.header {
  opacity: 0;
  transition: opacity 2s ease-out;
}
header.header .logo {
  align-self: start;
  justify-self: left;
}
header.header .logo a {
  text-decoration: none;
}
header.header .logo a:hover {
  font-style: normal;
}
header.header .menu {
  text-align: right;
  align-self: start;
  justify-self: right;
}
header.header .menu .menu-item {
  margin: 0;
}
header.header .menu .menu-item a {
  text-decoration: none;
}
header.header .toggle-autoscroll {
  text-align: left;
  align-self: end;
  justify-self: left;
  grid-row: 3;
  font-family: "Suisse Int'l Mono", monospace;
  font-size: 10px;
}

section.intro {
  margin-top: 0;
  text-align: center;
  font-family: "Suisse Int'l", "Helvetica", "Arial", sans-serif;
  line-height: 1.2;
}
section.intro .title {
  grid-template-rows: 100vh;
  margin-bottom: 10vw;
}
section.intro .title h1 {
  font-size: calc((10 * (100vw - 156px) / 12 + 108px) * 0.13);
  font-weight: normal;
  align-self: center;
}
@media (min-width: 660px) {
  section.intro .title h1 {
    font-size: calc((6 * (100vw - 156px) / 12 + 60px) * 0.13);
  }
}
section.intro p {
  font-size: calc((10 * (100vw - 156px) / 12 + 108px) * 0.08);
  margin: 0 0 2em;
  word-spacing: 1em;
}
@media (min-width: 660px) {
  section.intro p {
    font-size: calc((6 * (100vw - 156px) / 12 + 60px) * 0.08);
  }
}

section.projects {
  font-size: calc((12 * (100vw - 156px) / 12 + 132px) * 0.05);
  margin-top: calc(5vw + 150px);
}
@media (min-width: 660px) {
  section.projects {
    font-size: calc((6 * (100vw - 156px) / 12 + 60px) * 0.05);
  }
}
section.projects .title {
  text-align: center;
  font-size: calc((12 * (100vw - 156px) / 12 + 132px) * 0.05);
  margin: 0;
  font-weight: normal;
}
@media (min-width: 660px) {
  section.projects .title {
    font-size: calc((6 * (100vw - 156px) / 12 + 60px) * 0.05);
  }
}
section.projects ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Suisse Works", "Times New Roman", serif;
  position: relative;
}
section.projects ul li {
  z-index: 2;
  justify-self: start;
}
section.projects ul li:nth-of-type(2n + 1) {
  text-align: right;
  justify-self: end;
}
section.projects ul li a {
  text-decoration: none !important;
  position: relative;
  z-index: 3;
}
section.projects ul li img {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  left: 0;
  top: 0;
  pointer-events: none;
}
section.projects ul li:hover {
  z-index: 1;
}
section.projects ul li:hover img {
  display: block;
}

section.contact {
  text-align: center;
  margin-top: calc(150px + 5vw);
}
section.contact > div {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 150px - 5vw);
}
section.contact > div .email {
  position: sticky;
  top: calc(50% - 1em);
  font-size: calc((10 * (100vw - 156px) / 12 + 108px) * 0.1);
  font-weight: normal;
  align-self: center;
  margin: 0;
  line-height: 2em;
}
section.contact > div .email a {
  text-decoration: none;
}
section.contact > div .email a:hover {
  font-style: normal;
}
@media (min-width: 660px) {
  section.contact > div .email {
    font-size: calc((6 * (100vw - 156px) / 12 + 60px) * 0.1);
  }
}
/*# sourceMappingURL=index.c33e2392.css.map */
