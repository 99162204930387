$margins: 12px;
$gap: 12px;
$columns: 12;

$breakpoints: (
  'sm': 550,
  'md': 660,
  'lg': 770,
  'xl': 1024,
  'xxl': 1440
);

@function getWidth ($cols) {
  @return calc(#{$cols} * (100vw - #{($columns + 1) * $gap}) / #{$columns} + #{($cols - 1) * $gap})
}

@mixin bp ($bp) {
  @media (min-width: #{map-get($breakpoints, $bp)}px) {
    @content;
  }
}

@mixin bp-down ($bp) {
  @media (max-width: #{map-get($breakpoints, $bp) - 1}px) {
    @content;
  }
}

@mixin container {
  position: relative;
  padding-left: $margins;
  padding-right: $margins;
  display: grid;
  grid-template-columns: repeat(#{$columns}, 1fr);
  column-gap: $gap;
  align-content: start;

  &--nopad {
    padding-left: 0;
    padding-right: 0;
  }

  &--row-gap {
    row-gap: $gap;
  }
}

@mixin layout {
  .container {
    @include container();
    --columns: #{$columns} !important;

    @each $breakpoint, $value in $breakpoints {
      @include bp(#{$breakpoint}) {
        &-#{$breakpoint} {
          @include container();
        }
      }

      @include bp-down(#{$breakpoint}) {
        &-#{$breakpoint}-down {
          @include container();
        }
      }
    }
  }

  [class*="col-"] {
    @for $a from 1 through $columns {
      &.col-span-#{$a} {
        grid-column: span #{$a};

        &[class*="container"] {
          padding-left: 0;
          padding-right: 0;
          grid-template-columns: repeat($a, 1fr);
        }
      }

      &.col-#{$a} { grid-column: $a; }
      @for $b from 1 through $columns {
        &.col-#{$a}-#{$b} {
          grid-column: $a / span $b;

          &[class*="container"] {
            padding-left: 0;
            padding-right: 0;
            grid-template-columns: repeat($b, 1fr);
          }
        }
      }
    }

    @each $breakpoint, $value in $breakpoints {
      @include bp(#{$breakpoint}) {
        @for $a from 1 through $columns {
          &.col-#{$breakpoint}-span-#{$a} {
            grid-column: span #{$a};

            &[class*="container"] {
              padding-left: 0;
              padding-right: 0;
              grid-template-columns: repeat($a, 1fr);
            }
          }

          &.col-#{$breakpoint}-#{$a} { grid-column: $a; }
          @for $b from 1 through $columns {
            &.col-#{$breakpoint}-#{$a}-#{$b} {
              grid-column: $a / span $b;

              &[class*="container"] {
                padding-left: 0;
                padding-right: 0;
                grid-template-columns: repeat($b, 1fr);
              }
            }
          }
        }
      }

      @include bp-down(#{$breakpoint}) {
        @for $a from 1 through $columns {
          &.col-#{$breakpoint}-down-span-#{$a} {
            grid-column: span #{$a};

            &.container {
              padding-left: 0;
              padding-right: 0;
              grid-template-columns: repeat($a, 1fr);
              --columns: #{$a} !important;
            }
          }

          &.col-#{$breakpoint}-down-#{$a} { grid-column: $a; }
          @for $b from 1 through $columns {
            &.col-#{$breakpoint}-down-#{$a}-#{$b} {
              grid-column: $a / span $b;

              &.container {
                padding-left: 0;
                padding-right: 0;
                grid-template-columns: repeat($b, 1fr);
                --columns: #{$b} !important;
              }
            }
          }
        }
      }
    }
  }
}
