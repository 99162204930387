$font-main: 'Suisse Int\'l', 'Helvetica', 'Arial', sans-serif;
$font-serif: 'Suisse Works', 'Times New Roman', serif;
$font-mono: 'Suisse Int\'l Mono', monospace;

$col-main: #fcff57;

@import './general';
@import './grid';

@include reset;
@include layout;

.ukraine {
  background-color: #0057b7;
  position: fixed;
  height: 20px;
  width: 30px;
  display: block;
  bottom: 10px;
  right: 10px;
  z-index: 99;

  &::after {
    content: '';
    background-color: #ffd700;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// .container > * { background-color: #0ff2; }

.GridHelper {
  display: none;
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.3;
  pointer-events: none;
  z-index: 99999;
  align-content: stretch;

  > div {
    border-left: 1px solid #0ff;
    border-right: 1px solid #0ff;
    height: 100%;
    background-color: transparent;
  }
}

a:not(.disabled):hover { font-style: italic; }
a.disabled { text-decoration: line-through !important; cursor: default; }

body {
  background-color: #000;
  color: $col-main;
  font-family: $font-main;
}

@import './components/header';

section.intro {
  margin-top: 0;
  text-align: center;
  font-family: $font-main;
  line-height: 1.2;

  .title {
    grid-template-rows: 100vh;
    margin-bottom: 10vw;

    h1 {
      font-size: calc(getWidth(10) * 0.13);
      font-weight: normal;
      align-self: center;

      @include bp('md') {
        font-size: calc(getWidth(6) * 0.13);
      }
    }
  }

  p {
    font-size: calc(getWidth(10) * 0.08);
    margin: 0 0 2em;
    word-spacing: 1em;

    @include bp('md') {
      font-size: calc(getWidth(6) * 0.08);
    }
  }
}

section.projects {
  font-size: calc(getWidth(12) * 0.05);
  margin-top: calc(5vw + 150px);
  
  @include bp('md') {
    font-size: calc(getWidth(6) * 0.05);
  }

  .title {
    text-align: center;
    font-size: calc(getWidth(12) * 0.05);
    margin: 0;
    font-weight: normal;
    
    @include bp('md') {
      font-size: calc(getWidth(6) * 0.05);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: $font-serif;
    position: relative;

    li {
      z-index: 2;
      justify-self: start;

      &:nth-of-type(2n + 1) {
        text-align: right;
        justify-self: end;
      }

      a {
        text-decoration: none !important;
        position: relative;
        z-index: 3;
      }

      img {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        object-fit: contain;
        left: 0;
        top: 0;
        pointer-events: none;
      }

      &:hover {
        z-index: 1;

        img {
          display: block;
        }
      }
    }
  }
}

section.contact {
  text-align: center;
  margin-top: calc(150px + 5vw);

  > div {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 150px - 5vw);

    .email {
      position: sticky;
      top: calc(50% - 1em);
      font-size: calc(getWidth(10) * 0.1);
      font-weight: normal;
      align-self: center;
      margin: 0;
      line-height: 2em;

      a {
        text-decoration: none;
        &:hover { font-style: normal; }
      }

      @include bp('md') {
        font-size: calc(getWidth(6) * 0.1);
      }
    }
  }
}
