
@mixin reset {
  * {
    box-sizing: border-box;
    font-kerning: none;
  }
  
  body {
    margin: 0;
    overflow-x: hidden;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: currentColor;
  }

  a {
    color: currentColor;
  }

  body, button, input, textarea {
    font-family: $font-main;
    font-size: 1em;
  }
}
